<script setup lang="ts"></script>

<template>
  <svg
    width="42"
    height="32"
    viewBox="0 0 42 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0004 5C12.0004 5 10.6337 17.5259 0 18.3922C10.6337 19.259 12.0004 31.7849 12.0004 31.7849C12.0004 31.7849 13.3667 19.2586 24 18.3922C13.3667 17.5259 12.0004 5 12.0004 5Z"
      fill="#FCFCFC"
    />
    <path
      d="M34 0C34 0 33.0888 8.35077 26 8.92869C33.0888 9.50621 34 17.8566 34 17.8566C34 17.8566 34.9108 9.50621 42 8.92869C34.9108 8.35077 34 0 34 0Z"
      fill="#FCFCFC"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
