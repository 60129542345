<script setup lang="ts"></script>

<template>
  <div class="way-wrapper">
    <section class="way">
      <div class="content">
        <h1 class="content-title font--h1 text--wh">
          Be the first to earn with
          <span class="text--main-orange content-title-logo">Newtify</span>
        </h1>
        <p class="content-text font--b1--r text--wh">
            Join our early airdrop – make a few simple steps in the Newtify mobile app:
        </p>
      </div>
      <div class="way-image-decorator-wrapper">
        <img src="/way-decorator.png" alt="" class="way-image-decorator" />
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.way {
  position: relative;
  max-width: 1350px;
  padding: 100px 0 100px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-wrapper {
    position: relative;
    width: 100%;
    background-color: var(--bl);
  }

  &-image-decorator {

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.noise {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/noise.png');
  background-repeat: repeat;
  background-size: 200px 200px;
  mix-blend-mode: color-dodge;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-title {
    text-align: center;
    max-width: 680px;
    margin-bottom: 24px;
  }

  &-text {
    text-align: center;
    max-width: 680px;
    margin-bottom: 60px;
  }
}
</style>
