<script setup lang="ts">
import { DotLottieVue } from '@lottiefiles/dotlottie-vue';

const lottie = ref(null);

onMounted(() => {
  if (lottie.value) {
    const dotLottie = (lottie.value as typeof DotLottieVue).getDotLottieInstance();

     // Interval function
    function intervalFunction() {
      dotLottie.play();
      dotLottie.stop();
      setTimeout(() => {
        dotLottie.play();
      }, 7000);
    }

    // First call the function
    intervalFunction();
    // Set the interval
    setInterval(intervalFunction, 12000); // Interval for 12 seconds
  }
});
</script>

<template>
  <div class="main-wrapper">
    <img src="/animation-main-wrapper.png" alt="" class="main-decorator" />
    <img src="/animation-main.png" alt="" class="main" />
    <div class="main-content">
      <div class="main-content-overlay"></div>
      <div class="bonus">
        <span class="bonus-title">Bonus Reward</span>
        <div class="bonus-lottie">
          <DotLottieVue
            style="height: 100%; width: 100%"
            ref="lottie"
            src="/chest.lottie"
          />
        </div>
        <div class="bonus-button-wrapper">
          <div class="bonus-button-gray-overlay"></div>
          <div class="bonus-button-orange-overlay"></div>
          <div class="bonus-button">
            <span class="bonus-button-text">Claim</span>
          </div>
        </div>
      </div>
      <div class="top-card">
        <img
          src="/animation-top-card-shadow.png"
          alt=""
          class="top-card-shadow"
        />
      </div>
      <div class="middle-card">
        <img
          src="/animation-middle-card-shadow.png"
          alt=""
          class="middle-card-shadow"
        />
      </div>
      <div class="bottom-card"></div>
      <img src="/animation-cursor.png" alt="" class="cursor" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.main {
  position: absolute;
  top: 35px;
  left: 93px;
  width: 320px;

  &-decorator {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 490px;
  }

  &-wrapper {
    width: 490px;
    height: 645px;
    position: relative;
    overflow: hidden;
  }

  &-content {
    position: relative;
    top: 48px;
    left: 120px;
    width: 266px;
    height: 578px;
    overflow: hidden;

    &-overlay {
      opacity: 0;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(7, 1, 3, 0.32);
      backdrop-filter: blur(2px);
      border-radius: 35px;

      animation-name: overlay;
      animation-duration: 12s;
      animation-iteration-count: infinite;
    }
  }
}

.cursor {
  position: absolute;
  bottom: 80px;
  right: 56px;
  width: 106px;
  z-index: 20;

  animation-name: cursor;
  animation-duration: 12s;
  animation-iteration-count: infinite;
}

.top-card {
  position: absolute;
  z-index: 9;
  bottom: 91px;
  right: 0px;
  width: 267px;
  height: 380px;
  background-image: url('/animation-top-card.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  animation-name: top-card;
  animation-duration: 12s;
  animation-iteration-count: infinite;

  &-shadow {
    transform: translate(6px, 8px);
    width: 256px;
    height: 360px;
    opacity: 0;
    animation-name: top-card-shadow;
    animation-duration: 12s;
    animation-iteration-count: infinite;
  }
}

.middle-card {
  position: absolute;
  z-index: 8;
  bottom: 91px;
  right: 0px;
  width: 267px;
  height: 380px;
  background-image: url('/animation-middle-card.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: scale(0.895, 0.895) translate(3px, -30px);

  animation-name: middle-card;
  animation-duration: 12s;
  animation-iteration-count: infinite;

  &-shadow {
    transform: translate(4px, 8px);
    width: 258px;
    height: 360px;
    opacity: 0;
    animation-name: middle-card-shadow;
    animation-duration: 12s;
    animation-iteration-count: infinite;
  }
}

.bottom-card {
  position: absolute;
  z-index: 7;
  bottom: 91px;
  right: 0px;
  width: 267px;
  height: 380px;
  background-image: url('/animation-bottom-card.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: scale(0.895, 0.895) translate(3px, -30px);

  animation-name: bottom-card;
  animation-duration: 12s;
  animation-iteration-count: infinite;
}

.bonus {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 11;
  top: 51px;
  right: 7px;
  width: 253px;
  height: 480px;
  border-radius: 27px;
  background: rgba(7, 1, 3, 0.24);
  box-shadow: 0px 0px 19.826px 1.652px rgba(43, 43, 43, 0.32);
  animation-name: overlay;
  animation-duration: 12s;
  animation-iteration-count: infinite;

  &-title {
    width: 100%;
    color: #ffe600;
    font-family: 'Days One';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    display: block;
    padding-top: 25px;
  }

  &-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 208px;
    height: 40px;
    border-radius: 50px;
    border: 2.5px solid var(--monochromatic-white);
    background-image: url('/animation-reward-button.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &-text {
      color: var(--bg);
      text-align: center;
      font-size: 19.826px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }

    &-wrapper {
      position: relative;
      width: 208px;
      height: 40px;
      border-radius: 50px;
      margin-bottom: 17px;

      animation-name: bonus-button-wrapper;
      animation-duration: 12s;
      animation-iteration-count: infinite;
    }

    &-gray-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      background-color: var(--monochromatic-grey-400);
    }

    &-orange-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      border-radius: 50px;
      background-color: var(--color-main);

      animation-name: bonus-button-overlay;
      animation-duration: 12s;
      animation-iteration-count: infinite;
    }
  }

  &-lottie {
    width: 260px;
    height: 260px;
    margin-top: -80px;
  }
}

@keyframes cursor {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(0px, -140px);
  }
  20% {
    transform: translate(-100px, -150px);
  }
  30% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(0px, -140px);
  }
  50% {
    transform: translate(100px, -150px);
  }
  60% {
    transform: translate(60px, -50px);
  }
  70% {
    transform: translate(60px, -50px);
  }
  80% {
    transform: translate(60px, -50px);
  }
  90% {
    transform: translate(0px, 85px);
  }
  100% {
    transform: translate(0px, 85px);
  }
}

@keyframes top-card {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(-7px, 0px) rotate(-12deg);
  }
  20% {
    transform: translate(-295px, 0px) rotate(-12deg);
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.45));
  }
  100% {
    transform: translate(-295px, 0px) rotate(-12deg);
  }
}

@keyframes top-card-shadow {
  0% {
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes middle-card {
  0% {
    transform: scale(0.895, 0.895) translate(3px, -30px);
  }
  20% {
    transform: scale(0.895, 0.895) translate(3px, -30px);
  }
  30% {
    transform: scale(1, 1) translate(0, 0);
  }
  40% {
    transform: scale(1, 1) translate(0, 0);
  }
  45% {
    transform: scale(1, 1) translate(7px, 0px) rotate(12deg);
  }
  50% {
    transform: scale(1, 1) translate(295px, 0px) rotate(12deg);
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.45));
  }
  60% {
    transform: scale(1, 1) translate(295px, 0px) rotate(12deg);
  }
  100% {
    transform: scale(1, 1) translate(295px, 0px) rotate(12deg);
  }
}

@keyframes middle-card-shadow {
  0% {
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bottom-card {
  0% {
    transform: scale(0.895, 0.895) translate(3px, -30px);
  }
  20% {
    transform: scale(0.895, 0.895) translate(3px, -30px);
  }
  30% {
    transform: scale(0.895, 0.895) translate(3px, -30px);
  }
  40% {
    transform: scale(0.895, 0.895) translate(3px, -30px);
  }
  50% {
    transform: scale(0.895, 0.895) translate(3px, -30px);
  }
  60% {
    transform: scale(1, 1) translate(0, 0);
  }
  100% {
    transform: scale(1, 1) translate(0, 0);
  }
}

@keyframes overlay {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bonus-button-overlay {
  0% {
    width: 0;
  }
  70% {
    width: 0;
  }
  80% {
    width: 208px;
  }
  100% {
    width: 208px;
  }
}

@keyframes bonus-button-wrapper {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  95% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1);
  }
}
</style>
