<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="24"
    viewBox="0 0 38 24"
    fill="none"
  >
    <path
      d="M2 8.7255C11.8762 2.90963 21.144 1.83169 24.5434 2.0197M8.09786 22C20.3217 14.6536 31.7925 13.292 36 13.5295M5.511 14.3889C10.8538 10.5627 15.8676 9.85354 17.7066 9.97724"
      stroke="#4A3AFF"
      stroke-width="4"
      stroke-linecap="round"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
