<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="196"
    height="4"
    viewBox="0 0 196 4"
    fill="none"
  >
    <path
      d="M2 2H194"
      stroke="#4A3AFF"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="12 12"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
