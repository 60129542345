<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="26"
    viewBox="0 0 30 26"
    fill="none"
  >
    <path
      d="M29.4878 7.0748C29.605 7.62441 29.6666 8.19347 29.6666 8.77633C29.6666 19.1204 19.8818 23.9821 16.2964 25.4163C15.4612 25.7504 14.5386 25.7504 13.7034 25.4163C13.2552 25.237 12.7102 25.0042 12.0995 24.7138C7.82474 22.681 0.333252 17.8274 0.333252 8.77646C0.333252 4.11361 4.27315 0.333496 9.13325 0.333496C11.3871 0.333496 13.443 1.66327 14.9999 3.00016C16.5568 1.66327 18.6127 0.333496 20.8666 0.333496C22.7658 0.333496 24.5244 0.910693 25.9624 1.89222C27.7443 3.10851 29.0337 4.94569 29.4878 7.0748Z"
      fill="#FF731F"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
