<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="27"
    viewBox="0 0 25 27"
    fill="none"
  >
    <path
      d="M12.4999 0C12.4999 0 11.076 12.5252 0 13.3925C11.076 14.2598 12.4999 26.7849 12.4999 26.7849C12.4999 26.7849 13.924 14.2598 25 13.3925C13.924 12.5252 12.4999 0 12.4999 0Z"
      fill="#FCFCFC"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
