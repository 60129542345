<script setup lang="ts"></script>

<template>
  <svg
    width="230"
    height="207"
    viewBox="0 0 230 207"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_2527_1613" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M139.856 39C136.442 39 133.405 36.8346 132.293 33.6079L122.563 5.39207C120.084 -1.79736 109.916 -1.79736 107.437 5.39207L97.7074 33.6079C96.5948 36.8346 93.5576 39 90.1444 39H24C10.7452 39 0 49.7451 0 63V183C0 196.255 10.7452 207 24 207H206C219.255 207 230 196.255 230 183V63C230 49.7451 219.255 39 206 39H139.856Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M139.856 39C136.442 39 133.405 36.8346 132.293 33.6079L122.563 5.39207C120.084 -1.79736 109.916 -1.79736 107.437 5.39207L97.7074 33.6079C96.5948 36.8346 93.5576 39 90.1444 39H24C10.7452 39 0 49.7451 0 63V183C0 196.255 10.7452 207 24 207H206C219.255 207 230 196.255 230 183V63C230 49.7451 219.255 39 206 39H139.856Z"
      fill="white"
      fill-opacity="0.4"
    />
    <path
      d="M97.7074 33.6079L96.7621 33.2819L97.7074 33.6079ZM132.293 33.6079L133.238 33.2819L132.293 33.6079ZM107.437 5.39207L106.492 5.06608L107.437 5.39207ZM121.618 5.71806L131.347 33.9339L133.238 33.2819L123.508 5.06608L121.618 5.71806ZM98.6528 33.9339L108.382 5.71806L106.492 5.06608L96.7621 33.2819L98.6528 33.9339ZM24 40H90.1444V38H24V40ZM1 63C1 50.2974 11.2975 40 24 40V38C10.1929 38 -1 49.1928 -1 63H1ZM1 183V63H-1V183H1ZM24 206C11.2975 206 1 195.703 1 183H-1C-1 196.807 10.1929 208 24 208V206ZM206 206H24V208H206V206ZM229 183C229 195.702 218.703 206 206 206V208C219.807 208 231 196.807 231 183H229ZM229 63V183H231V63H229ZM206 40C218.703 40 229 50.2974 229 63H231C231 49.1928 219.807 38 206 38V40ZM139.856 40H206V38H139.856V40ZM96.7621 33.2819C95.7885 36.1052 93.1309 38 90.1444 38V40C93.9842 40 97.4011 37.5639 98.6528 33.9339L96.7621 33.2819ZM131.347 33.9339C132.599 37.5639 136.016 40 139.856 40V38C136.869 38 134.212 36.1052 133.238 33.2819L131.347 33.9339ZM123.508 5.06608C120.719 -3.02203 109.281 -3.02203 106.492 5.06608L108.382 5.71806C110.552 -0.572693 119.448 -0.572692 121.618 5.71806L123.508 5.06608Z"
      fill="#4A3AFF"
      fill-opacity="0.24"
      mask="url(#path-1-inside-1_2527_1613)"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
