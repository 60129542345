<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
  >
    <path
      d="M15.2719 3.91819C15.9627 2.24928 18.3256 2.24928 18.9814 3.91819L21.4974 10.3207C21.5893 10.5545 21.8059 10.7152 22.057 10.7357L28.5397 11.2651C30.2974 11.4086 30.9989 13.5944 29.6613 14.76L24.6926 19.09C24.5087 19.2503 24.4254 19.4968 24.475 19.7338L25.9745 26.8895C26.349 28.677 24.3195 30.0099 22.8396 28.9483L17.2928 24.9695C17.0631 24.8047 16.7526 24.8047 16.5194 24.9695L10.8893 28.9483C9.38718 30.0099 7.38555 28.677 7.79756 26.8895L9.44689 19.7338C9.50151 19.4968 9.42334 19.2503 9.24281 19.09L4.36465 14.7601C3.05145 13.5944 3.79869 11.4086 5.55944 11.2651L12.0534 10.7357C12.305 10.7152 12.525 10.5545 12.6218 10.3207L15.2719 3.91819Z"
      fill="#FF731F"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
